import Vue from "vue";
import longTime from "@/utils/longTime";
import moment from "moment";

const MessageMixin = Vue.extend({
  props: {
    first: Boolean,
    last: Boolean,
    middle: Boolean,
    uuid: String,
    from: String,
    to: String,
    createAt: String,
    twilioStatus: String,
    message: String,
    mediasUrl: Array,
    messagingServiceSid: String,
    twilioSid: String,
    status: String,
    replyToSmsUuid: String,
  },
  methods: {
    longTime,
    getHours(date: any) {
      const d = moment(date).format("MM/DD/YY , HH:mm");
      return d;
    },
  },
});

export default MessageMixin;
