<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-form
    ref="form"
    v-model="formValid"
    class="d-flex flex-column fill-height position-relative smsform-wrappper"
    :style="{
      'max-height': `${chatHeight - delta}px`,
    }"
    v-resize="setChatHeight"
  >
    <h3
      class="mt-2 ml-2"
      v-if="contactSelect != undefined && contactSelect != null"
    >
      {{
        "To: " + contactSelect.name != undefined
          ? contactSelect.name
          : contactSelect.number
      }}
    </h3>
    <h4
      class="ma-1 text--secondary"
      v-if="contactSelect != undefined && contactSelect != null"
    >
      {{ contactSelect.number }}
    </h4>

    <div
      class="
        message-list-container
        flex-column
        justify-end
        fill-height
        overflow-y-auto
      "
    >
      {{/** List messages */}}
      <div
        v-if="contactSelect == undefined || contactSelect == null"
        class="d-flex flex-column align-start justify-start px-2 py-2"
      >
        Select contact
      </div>

      <div
        v-if="contactSelect != undefined && contactSelect != null"
        ref="smscontentScroll"
        class="smscontent px-2 pb-2"
        :style="{
          height: `${chatHeight - 170}px`,
        }"
      >
        <template v-if="loadingbtw">
          <div class="d-flex justify-center my-4">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </template>
        <template v-for="(message, index) in messagesFiltered">
          <v-sheet
            v-if="isDiferentMessage(index, messagesFiltered)"
            :key="'s-' + index"
            class="mt-2 px-2 d-flex align-center cursor-pointer"
            :class="{
              'justify-end': isSend(message),
              'ml-auto': isSend(message),
            }"
            color="var(--crm-background)"
            style="min-height: 24px; top: 0; z-index: 1"
            v-ripple
            rounded
            small
          >
            <!--  <span
              class="text-caption font-weight-bold grey--text darken-4"
              v-if="isSend(message)"
            >
              {{ formatDate(message.createAt) }}
            </span> -->
            <span class="text-caption font-weight-bold grey--text darken-4">
              {{ formatDate(message.createAt) }}
            </span>
          </v-sheet>
          <message
            v-if="contactSelect != undefined"
            :key="index"
            :messageProps="{
              ...message,
              first: isFirst(index, messagesFiltered),
              last: isLast(index, messagesFiltered),
              middle:
                isMiddle(index, messagesFiltered) ||
                (isFirst(index, messagesFiltered) &&
                  isFirst(index, messagesFiltered)),
            }"
          />
        </template>
      </div>
    </div>
  </v-form>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import Vue from "vue";

import Message from "./Message.vue";
import MessageListHeader from "./MessageListHeader.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { notifyError } from "@/components/Notification";
import ReceivedSMS from "@/models/ReceivedSMS";
import { SMS } from "@/models/Sms";
import { getAPI } from "@/api/axios-base";
import moment from "moment";

export default Vue.extend({
  components: { Message, MessageListHeader },
  name: "sms-message-list",
  props: {
    // Contact to filter messages
    contact: Object,
  },
  data() {
    return {
      formValid: false,
      intervalSMS: null,
      loadingSendMessage: false,
      chatHeight: 0,
      contactNumber: "",
      loadingbtw: false,
      smsContact: [],
      smsfilters: [],
      sheduleMessageRequest: 0,
    };
  },
  computed: {
    ...mapGetters("crmSMSModule", [
      "messagesPerPatient",
      "numberMessages",
      "findMessages",
    ]),
    ...mapGetters(["isAdmin", "isSuper"]),
    ...mapState("crmSMSModule", [
      "messages",
      "messagesFilters",
      "loading",
      "contactSelect",
      "numberSelect",
    ]),
    ...mapState(["profile"]),
    // Filter by contact sended from PersonList
    messagesFiltered() {
      const contacto = this.contactSelect;
      if (
        contacto != null &&
        this.numberSelect != null &&
        this.smsfilters.length != 0
      ) {
        const msg = this.smsfilters.filter(
          (sms) => sms.from == contacto.number || sms.to == contacto.number
        );

        return msg;
      }

      if (contacto == null) {
        return [];
      } else {
        const msg = this.smsContact.filter(
          (sms) => sms.from == contacto.number || sms.to == contacto.number
        );
        return msg;
      }
    },
    contactS() {
      return this.contact != null ? this.contact.phone.substr(2) : "";
    },
    delta() {
      return this.$vuetify.breakpoint.smAndDown ? 52 : 62;
    },
  },
  watch: {
    contact(val) {
      if (val != undefined || val != null) {
        this.contactNumber = "";
      }
    },
    contactSelect(val) {
      if (val != undefined && val != null) {
        this._smsBtw();
      } else {
        clearInterval(this.intervalSMS);
      }
    },
  },
  methods: {
    ...mapActions("crmSMSModule", [
      "actSendMessage",
      "actListMessages",
      "actReadMessages",
      "getUnread",
    ]),
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutLoadingBtw",
      "mutnumberSelect",
    ]),
    /*  async initRequestSms() {
      (this).loadingbtw = true;
      if (this.contactSelect != null) {
        if (this.isAdmin || this.isSuper) {
          await (this)._smsBtw();

          (this).intervalSMS = setInterval((this)._smsBtw, 4000);
        } else {
          await (this).getsmsFiltered();

          (this).intervalSMS = setInterval(
            (this).getsmsFiltered,
            4000
          );
        }
      } else {
        clearInterval((this).intervalSMS);
      }
    }, */

    async getsmsFiltered() {
      if (this.contactSelect != null) {
        const numer = this.contactSelect.number;
        if (numer != undefined && numer != null && numer != "") {
          getAPI(`/twiliocloud/getSMS/5000/0`, {
            params: { phoneNumber: numer },
          })
            .then((res) => {
              this.smsContact = res.data.result;
            })
            .catch((error) => {
              let mess = error.response.data.message;

              if (mess.includes("[")) {
                mess = mess.replace("[", "");
                mess = mess.replace("]", "");
              }
              notifyError(error.response.data, `An error occurred: ${mess}`);
            });
        } else {
          this.smsContact = [];
        }
      }
    },

    _smsBtw() {
      if (this.contactSelect != null) {
        const number2 =
          this.numberSelect != null && this.numberSelect != undefined
            ? this.numberSelect
            : this.profile.uuid;

        this.loadingbtw = true;
        getAPI("/twiliocloud/getSMS/5000/0", {
          params: {
            employeeUuid: number2,
            phoneNumber: this.contactSelect.number,
          },
        })
          .then((res) => {
            this.loadingbtw = false;
            if (number2 == this.profile.phone) {
              this.smsContact = res.data.result;
            } else {
              this.smsfilters = res.data.result;
            }
          })
          .catch((error) => {
            this.loadingbtw = false;
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    isFirst(index, list) {
      const previous = list[index - 1] || false;
      const next = list[index + 1] || false;
      const current = list[index];

      // Si los vecinos no son igual a mi
      if (
        previous &&
        next &&
        previous.from != current.from &&
        next.from != current.from
      )
        return false;

      // Si antes no hay nada y el enterior no es igual
      if (previous == false && previous.from != current.from) return true;
      // Si antes ahy uno pero no es igual
      else if (previous && previous.from != current.from) return true;

      return false;
    },
    isLast(index, list) {
      const previous = list[index - 1] || false;
      const next = list[index + 1] || false;
      const current = list[index];

      // Si no tiene nada alante y atras false
      if (
        next &&
        previous &&
        next.from != current.from &&
        previous.from != current.from
      )
        return false;

      // Si antes no hay nada y el enterior no es igual
      if (next == false && next.from != current.from) return true;
      // Si antes ahy uno pero no es igual
      else if (next && next.from != current.from) return true;

      return false;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, data] = date.split("-");
      const day = data.substr(-20, 2);
      return `${month}/${day}/${year}`;
    },
    isMiddle(index, list) {
      const previous = list[index - 1] || false;
      const next = list[index + 1] || false;
      const current = list[index];

      // Solo si tiene anterior y siguiente
      if (
        previous &&
        next &&
        previous.from == current.from &&
        next.from == current.from
      )
        return true;
    },
    setChatHeight() {
      this.chatHeight = this.$vuetify.breakpoint.height;
    },

    /*   sheduleRequestSMS(): void {
      clearInterval((window as any).sheduleMessageRequest);
      (window as any).sheduleMessageRequest = setInterval(() => {
        this.actListMessages();
      }, 5000);
    }, */
    isDiferentMessage(index, messages) {
      if (messages[index - 1]) {
        const day1 = moment(messages[index - 1].createAt).get("day");
        const day2 = moment(messages[index].createAt).get("day");

        if (day1 != day2) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isSend(message) {
      return message.from === this.profile.phone;
    },

    newContact() {
      this.mutnumberSelect(null);
      this.mutSetContact(null);
    },
  },
  async mounted() {
    this.setChatHeight();
  },
  updated() {
    this.$nextTick(() => {
      if (this.contactSelect) {
        const div = this.$refs.smscontentScroll;
        div.scrollTop = div.scrollHeight;
      }
    });
  },

  destroyed() {
    this.newContact();
    this.mutSetContact(null);
  },
});
</script>

<style lang="scss" scoped>
.smscontent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
}
.smsform-wrappper {
  height: 100%;
  margin-top: 16px !important;
  background-color: rgb(247, 247, 244) !important;
  border-radius: 15px !important;
  margin-right: 5px !important;
}
.message-list-container {
  .message-list {
    overflow-y: none;
  }

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #1e1e24;
  }

  &::-webkit-scrollbar-thumb {
    background: #6649b8;
  }
}
</style>
