<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-navigation-drawer
    class="person-list overflow-hidden"
    width="100%"
    v-resize="setHeight"
    permanent
  >
    <v-row class="mt-4 px-1 mb-1" no-gutters>
      <v-col cols="12" sm="4">
        <v-autocomplete
          outlined
          dense
          :loading="loadempl"
          :disabled="loadempl"
          :items="_showWorkers"
          v-model="phoneNumber"
          item-text="fullname"
          item-value="uuid"
          clearable
          @click:clear="resetFilter"
          label="Select Worker"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.fullname"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.origin"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-html="data.item.phone ? data.item.phone : 'No Phone'"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>

      <v-col cols="12" sm="4" class="px-1">
        <v-text-field
          prepend-inner-icon="mdi-numeric-positive-1"
          outlined
          v-model="phoneText"
          dense
          clearable
          label="Phone"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-autocomplete
          outlined
          dense
          :loading="loadempl"
          :disabled="loadempl"
          :items="contacts"
          v-model="selectedPerson"
          item-text="name"
          item-value="number"
          clearable
          label="Select Contact"
        >
          <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.origin"
                ></v-list-item-subtitle>
                <v-list-item-subtitle
                  v-html="data.item.number ? data.item.number : 'not phone'"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>

    <template v-if="loading">
      <div class="d-flex justify-center my-4">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>

    {{/* List of contacts */}}
    <template v-if="contacts.length == 0">
      <p class="ma-2 red--text">There is no messages</p>
    </template>

    <v-list class="overflow-y-auto" :height="chatHeight - 48 * 2 - 10">
      <v-list-item-group v-model="selectedPerson" color="grey">
        <v-divider v-if="contactSmsList.length > 0" inset />

        <template v-for="(contact, index) in contacts">
          <v-list-item
            :class="contact.number == _numbercontact ? 'selected' : ''"
            :disabled="loading"
            :key="contact.number"
            link
            @click="setContact(contact)"
          >
            <v-list-item-content>
              <v-list-item-title
                :class="
                  noReadSms(contact) != 0
                    ? 'font-weight-bold d-flex pr-4'
                    : 'd-flex pr-4'
                "
              >
                <!--  <v-icon>{{ contactIcon(contact) }}</v-icon> -->
                {{ contact.name }}
                <v-spacer></v-spacer>
                <template v-if="noReadSms(contact) != 0">
                  <v-badge color="green" :content="noReadSms(contact)">
                    <span class="messages">messages</span>
                  </v-badge>
                </template>
              </v-list-item-title>
              <v-list-item-subtitle>{{ contact.origin }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ contact.number }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <!-- <btn-make-call :fromcall="true" :numberPatient="contact.number" /> -->

              <template>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="success"
                      v-on="on"
                      icon
                      :disabled="dialogmakeCall"
                      @click="makeCallV(contact.number)"
                    >
                      <v-icon>mdi-phone-forward</v-icon>
                    </v-btn>
                  </template>
                  <span>Make Call</span>
                </v-tooltip>
              </template>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="contact.length - 1 != index"
            :key="'d-' + index"
            inset
          />
        </template>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Contact, SMS } from "@/models/Sms";
import Vue from "vue";

import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import BtnMakeCall from "@/views/CallCenter/buttons/BtnMakeCall.vue";
import formatPhone from "@/utils/formatPhone";
import { getAPI } from "@/api/axios-base";

export default Vue.extend({
  name: "chat-person-list",
  components: { BtnMakeCall },
  data() {
    return {
      selectedPerson: null,
      showOnline: false,
      interval: null,
      loading: false,
      chatHeight: 0,
      phoneNumber: "",
      phoneText: "",
      loadempl: false,
      query: "",
    };
  },
  computed: {
    ...mapState("crmCallCenterModule", ["dialogmakeCall"]),
    ...mapGetters("crmSMSModule", ["messagesPerPatient"]),
    ...mapState(["profile"]),
    ...mapGetters(["isAdmin", "isSuper", "getPhone"]),
    ...mapState("crmSMSModule", [
      "messages",
      "contacts",
      "unreads",
      "contactSelect",
      "numberSelect",
    ]),
    ...mapState("crmEmployeeModule", ["workers"]),
    _showWorkers() {
      if (this.workers.length == 0) {
        return [];
      }
      const filt = this.workers.filter(
        (w) => w.phone != null && w.phone != undefined
      );
      return filt;
    },

    _numbercontact() {
      if (this.contactSelect == null) {
        return "";
      }
      return this.contactSelect.number;
    },

    contactSmsList() {
      return Array.from(this.messagesPerPatient.keys());
    },

    isAdministrator() {
      return this.isAdmin || this.isSuper;
    },
  },
  async mounted() {
    this._getNumberContacts();
    this.loadempl = true;

    await this.getWorkers();
    this.loadempl = false;
    if (localStorage.getItem("uuidemployee")) {
      this.phoneNumber = localStorage.getItem("uuidemployee");
    }
  },
  destroyed() {
    this.mutnumberSelect(null);
    this.mutSetContact(null);
    clearInterval(this.interval);
    localStorage.removeItem("uuidemployee");
  },

  watch: {
    selectedPerson(val) {
      if (val != undefined && val != null) {
        const contac = this.contacts.filter((c) => c.number == val);
        if (contac.length > 0) {
          this.setContact(contac[0]);
        }
      }
    },
    /* contactSelect: {
      handler() {
        (this).getsmsFiltered();
      },
    }, */

    numberSelect(val) {
      if (val == null) {
        this.phoneNumber = "";
        this.phoneText = "";
      } else {
        clearInterval(this.interval);
      }
    },

    phoneNumber(val) {
      if (val != null && val != undefined && val != "") {
        this._getNumberContacts(val);
        this.mutnumberSelect(val);
      } else {
        if (this.phoneText == "" || this.phoneText == null) {
          this._getNumberContacts();
          this.mutnumberSelect(null);
          this.mutSetContact(null);
        }
      }
    },
    phoneText(val) {
      if (val != null && val != undefined && val != "" && val.length == 10) {
        if (val[0] != "+") {
          val = "+1" + val;
        }

        this._getNumberContacts(val);
        this.mutnumberSelect(val);
      } else if (val != null && val.length > 0 && val.length < 10) {
        //nada
      } else {
        if (this.phoneNumber == "" || this.phoneNumber == null) {
          this._getNumberContacts();
          this.mutnumberSelect(null);
          this.mutSetContact(null);
        }
      }
    },
  },

  methods: {
    ...mapActions("crmSMSModule", ["actContac"]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutnumberSelect",
      "mutcontacts",
      "mutSetMessagesFilters",
    ]),
    ...mapMutations("crmCallCenterModule", ["mutDialogmakeCall"]),
    openCall(phone) {
      localStorage.setItem("number", phone);
      this.mutDialogmakeCall(true);
    },
    makeCallV(item) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: item,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },
    resetFilter() {
      this.phoneNumber = "";
    },

    setHeight() {
      this.chatHeight = this.$vuetify.breakpoint.height;
    },

    async _getNumberContacts(number) {
      let phone = number
        ? number
        : this.profile != null && this.profile != undefined
        ? this.profile.uuid
        : null;
      if (phone != null) {
        await this.actContac(phone);
      } else {
        this.mutcontacts([]);
      }
    },
    noReadSms(contact) {
      const noread = this.unreads.filter((ms) => ms.from == contact.number);
      if (noread.length != 0) {
        return noread[0].cant;
      }
      return 0;
    },
    async getWorkers() {
      await this.actGetEmployees();
    },

    setContact(account) {
      this.mutSetContact(account);
      this.$emit("contact", account);
    },
  },
});
</script>

<style lang="scss" scope>
.messages {
  font-size: 12px;
}
.selected {
  background-color: rgba(206, 203, 203, 0.514);
}
.person-list {
  .v-input {
    .v-input__slot {
      box-shadow: none !important;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "placeholder": "Search contacts"
  },
  "es": {
    "placeholder": "Buscar contactos"
  }
}
</i18n>
