
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import { mapMutations, mapState } from "vuex";
import formatPhone from "@/utils/formatPhone";
import { Contact } from "@/models/Sms";
export default Vue.extend({
  name: "message-list-header",
  props: {
    value: String,
  },
  data() {
    return {
      number: "",
      rules,
    };
  },
  watch: {
    value(val: string): void {
      this.number = val;
    },
    number(val) {
      if (val.length >= 10) {
        let num = val;
        num = num.replace("+1", "");
        num = num.replaceAll("(", "");

        num = num.replaceAll(")", "");

        num = num.replaceAll("-", "");

        num = num.replaceAll(" ", "");

        num = num.replaceAll("\t", "");
        this.number = num;
        this.number = formatPhone(this.number);
      }
    },
  },
  computed: {
    ...mapState("crmSMSModule", ["contacts"]),
  },
  methods: {
    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    changeNumber() {
      let num = this.number;

      if (num.length == 10) {
        if (!num.includes("+1")) {
          num = "+1" + num;
        }

        const cont = this.contacts.filter((c: Contact) => c.number == num);

        if (cont.length > 0) {
          this.mutSetContact(cont[0]);
        } else {
          this.mutSetContact({ number: num });
        }
      } else {
        this.mutSetContact(null);
      }
    },
  },
});
